<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:title>
      <v-btn-toggle v-if="getAccess('object.showPlanning')" v-model="typeView" mandatory borderless dense>
        <v-btn :value="0" color="#d0bf9c">
          <span class="hidden-lg-and-down">Информация</span>
          <v-icon :right="$vuetify.breakpoint.lgAndUp"> mdi-folder-information-outline </v-icon>
        </v-btn>
      </v-btn-toggle>
    </template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
      <v-btn color="primary" @click="createNew()" title="Создать элемент"><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn></template
    >
    <portal to="v-main">
      <edit-dialog v-if="typeView == 0" v-model="showEditDialog" :id="idEdit" />
    </portal>
    <v-tabs-items v-model="typeView" class="transparent" touchless>
      <v-tab-item :style="{ height: $root.windowSize.contentHeight - 16 + 'px' }">
        <a-table-f-api ref="table" :api="url" :model="model" :useQuery="true" :defaults="defaults" @click="onClickRow($event)" />
      </v-tab-item>
      <v-tab-item :style="{ height: $root.windowSize.contentHeight - 16 + 'px' }">
        <a-table-f-api ref="table" :api="'/7perfect/information_test'" :model="modelTest" :useQuery="false" :defaults="defaults" @click="onClickRow($event)" />
      </v-tab-item>
    </v-tabs-items>
  </static-fullscreen-card>
</template>

<script>
import { getAccess, keyDetect } from "@/components/mixings";
export default {
  mixins: [getAccess, keyDetect],
  components: {
    editDialog: () => import("./dialogs/infoDialog"),
  },
  data() {
    return {
      idEdit: 0,
      typeView: 0,
      showEditDialog: false,
      title: "",
      model: this.$store.getters["config/get"].models.info.list,
      modelTest: [
        { name: "id", title: "#", type: "id", sortable: true, width: 50 },
        {
          name: "name",
          title: "Название",
          type: "string",
          sortable: false,
          width: 120,
          formula: '((d) => {return d.data?.name || "";})',
        },
        { name: "createdon", title: "Дата создания", type: "datetime", sortable: true, width: 120 },
        { name: "status", title: "Статус", type: "select", options: "status_active", sortable: true, width: 120 },
      ],
      url: "/7perfect/information",
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  created() {
    this.$root.title = "Информация";
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
